import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded active-trail aboutmigraines">
                      <a href="/about-migraine" title="About Migraines" className="about-migraine">
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a href="/migraine-symptoms" title="Migraine Types & Symptoms">
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf potentialmigrainecauses">
                          <a
                            href="/causes"
                            title="Potential Migraine Causes"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last active-trail whatcanyoudoaboutit">
                          <a
                            href="/what-can-you-do"
                            title="Treatment and Relief"
                            className="active"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf ourproducts">
                      <a href="/products" title="Our Products">
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf migrainetriggerdiary">
                      <a
                        href="/trigger-diary"
                        title="Migraine Trigger Diary"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">
                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-12" className="node">
                      <div className="content clear-block">
                        <h1 className="WhatCanDoText">
                          What Can You Do About It
                        </h1>
                        <h2 className="WhatCanDoText">?</h2>
                        <p>
                          <strong>
                            If you suffer from migraines you may find it useful to consult with your doctor to help.
                          </strong>
                          Not every migraine is the same, even for the same person,
                          so you may want to use different treatments for different migraines.
                        </p>
                        <p>
                          There is currently no cure for migraine, but there are
                          several treatments available to ease the symptoms.
                        </p>
                        <h2>Over the counter medicines</h2>
                        <p>
                          If you think you may be suffering from migraines, it
                          is a good idea to see a doctor to gain an accurate
                          diagnosis. You may find that painkillers such as
                          paracetamol, ibuprofen or aspirin; or combination
                          treatments containing an analgesic and an
                          anti-sickness agent, can help to reduce your migraine
                          symptoms. However, if you find that you cannot manage
                          your migraines using these medicines, your GP may
                          recommend something stronger.
                        </p>
                        <p>
                          <strong>Painkillers</strong>, such as paracetamol,
                          ibuprofen and aspirin may help to relieve your
                          headache, if taken at the first signs of a migraine
                          attack, allowing them time to absorb into the
                          bloodstream. When taking these medicines, always make
                          sure you read the instructions on the packaging and
                          follow the dosage recommendations. Children under 16
                          should not take aspirin unless it is under the
                          guidance of a healthcare professional.
                        </p>
                        <p>
                          <strong>Triptan medicines</strong> taken with
                          painkillers and possibly anti-sickness medication may
                          be recommended to you by your GP if ordinary
                          painkillers are not relieving your migraine symptoms.
                          Triptans are available as tablets, injections and
                          nasal sprays. You will usually have a follow-up
                          appointment after your first course of treatment. If
                          the medication was ineffective or caused unpleasant
                          side effects, your GP may recommend a different type
                          of triptan.
                        </p>
                        <p>
                          <strong>Combination treatments</strong> work to treat
                          your headache and nausea symptoms, containing both
                          painkillers and anti-sickness medicines
                          (anti-emetics). They should be taken at the first sign
                          of a migraine attack. One such medication is
                          MIGRALEVE™, which contains two analgesics, paracetamol
                          and codeine to treat your head pain, together with
                          buclizine to relieve nausea. Click here to find out
                          more about our products.
                        </p>
                        <h2>Avoiding Potential Migraine Triggers</h2>
                        <p>
                          Several factors have been identified that may trigger
                          a migraine, these include but are not limited to:
                          emotional, physical, dietary and environmental
                          triggers. By observing these and keeping a{" "}
                          <a href="/trigger-diary">Migraine Trigger</a>{" "}
                          Diary along with some lifestyle changes, it may help
                          you manage your migraine attacks.
                        </p>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-5" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/ma.png"
                            />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Tips" href="/tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/bm.png"
                            />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="trigger_diaryBg">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/td.png"
                            />
                            <div>
                              <h2>trigger diary</h2>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                title="Try a trigger diary"
                                href="/trigger-diary"
                              >
                                Try a trigger diary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
